// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_pEGyi{align-items:center;background-color:var(--table-cell-warning-background-color);display:flex;height:calc(3rem - 1px);margin-top:1px;padding:0 1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_pEGyi"
};
module.exports = ___CSS_LOADER_EXPORT___;
